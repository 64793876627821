import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, Modal, ButtonsGroup } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { FieldCustomForm, UserSelect } from './../../../scenes';
import { FranchiseService } from './../../../services';
import { isEmail, findPermissionProps } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 1024){
    errors.name = TEXT.validation.maxlength(1024);
  }

  if (!values.position) {
    errors.position = TEXT.validation.required;
  }else if(values.position <= 0){
    errors.position = TEXT.validation.minlength(1);
  }

  if (!values.email) {
    errors.email = TEXT.validation.required;
  } else if (isEmail(values.email)) {
    errors.email = TEXT.validation.email_invalid;
  } else if (values.email.length > 255){
    errors.email = TEXT.validation.maxlength(255);
  }

  if (values.office_phone) {
    if (values.office_phone.length > 255) {
      errors.office_phone = TEXT.validation.maxlength(255);
    }
  }

  if (!values.user) {
    errors.user = TEXT.validation.required;
  }

  return errors;

}

class Page__StoreForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        //type: 'category',
        entity: null,
        categories: [],
        fieldFormShow: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.franchise, this.props.t('text.franchises')) });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: this.props.t('text.new_entry') });
      }

    }

    /*
    ** @Service
    ** @Description: Save Store
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      FranchiseService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.store_created')
        });

        // @Redirect
        this.props.history.push('/franchises');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Store
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      FranchiseService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.store_updated')
        });

        // @Redirect
        this.props.history.push('/franchises');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Store
    */

    _Find = (id) => {

      // @Service
      FranchiseService.find(id).then((response) => {

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.franchise._id,
          entity: response.franchise,
          title: response.franchise.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));

        // @Contact
        this.props.dispatch(change(form, 'email', this.state.entity.email ));
        this.props.dispatch(change(form, 'office_phone', this.state.entity.office_phone ));


        // @Fields
        this.props.dispatch(change(form, 'fields', this.state.entity.fields ));

        // @Payments
        this.props.dispatch(change(form, 'fiserv_id', this.state.entity.fiserv_id ));
        this.props.dispatch(change(form, 'user', this.state.entity.user._id ));


      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };



    /*
    ** @Event
    ** @Description: Show Form Fields
    */

    handleField = () => {
      this.setState({ fieldFormShow: true });
    };

    /*
    ** @Event
    ** @Description: Close Form Fields
    */

    handleFieldClose = () => {
      this.setState({ fieldFormShow: false });
    };

    /*
    ** @Event
    ** @Description: OnChange Custom Field
    */

    handleOnSubmitCustomField = (fields) => {
      this.props.dispatch(change(this.props.form, 'fields', fields ));
      this.handleFieldClose();
    };

    // @Render
    render(){

      const { handleSubmit, invalid, fields, t } = this.props;
      const { permission, name, loading, title, submited, fieldFormShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/franchises"
                icon="back"
                size="medium"
              >
                {t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              <Modal
                title={this.props.t('text.custom_fields')}
                status={fieldFormShow}
                handleCancel={() => this.handleFieldClose()}
              >
                <FieldCustomForm
                  component={API.permissions.franchise}
                  currentValues={fields}
                  onSubmit={this.handleOnSubmitCustomField}
                />
              </Modal>

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.general')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={t('field.name')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={t('field.status')}
                          required
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                        <Field
                          name="position"
                          component={ InputTextField }
                          label={t('field.position')}
                          type="number"
                          required
                          min={1}
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Contact information */}
                  <Content disablePadding divider>
                    <Toolbar
                      title={t('text.contact')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="email"
                          component={ InputTextField }
                          label={t('field.email')}
                          type="email"
                          required
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="office_phone"
                          component={ InputTextField }
                          label={t('field.phone_office')}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Payments information */}
                  <Content disablePadding divider>
                    <Toolbar
                      title={t('text.payments_information')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="fiserv_id"
                          component={ InputTextField }
                          label={t('field.fiserv_id')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="user"
                          component={ UserSelect }
                          label={t('field.sales_user')}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/franchises"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__StoreForm = reduxForm({
  form: 'Page__StoreForm',
  validate,
  initialValues: {
    enabled: true,
    position: 1,
    images: [],
    schedule: []
  }
})(Page__StoreForm);

const selector = formValueSelector('Page__StoreForm');

Page__StoreForm = connect(state => {
  const fields = selector(state, 'fields') || [];
  return { fields }
})(Page__StoreForm)

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__StoreForm));
